// variables
$color-white: #fff;
$color-black: #000;
$color-grey1: #F0F0F0;
$color-green-border: #d6ffc7;
$color-brand: #FF7B01;
$color-brand-yellow: #fcdb66;
$color-brand-dark: #6C6C6C;
$color-red: #f00;
$color-header-btn-action: $color-brand;
$color-header-create: #0138FF;
$color-header-update: #4285f4;
$bg-header-main: $color-brand;
$bg-header-btn-action: $color-white;
$bg-footer: #212121;

$color-strct1: #0138FF;
$color-strct2: #AF00EC;

//
// font sizes
$font-size-h1: 36px;
$font-size-h2: 32px;
$font-size-h3: 28px;
$font-size-h4: 25px;
$font-size-h5: 21px;
$font-size-h6: 18px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-xsmall: 12px;
